<template>
    <div>
        <Menu></Menu>
        <div class="w1200 m-auto">
            <p class="fs14 color-1 mt10"><span @click="$router.push('/')" class="index">首页</span> > <span @click="Fallback"
                    class="index">视频课程</span></p>
            <div class="videoBox">
                <p class="fs24 textAliginC mt20">{{ title }}</p>
                <div class="video">
                    <video controls width="100%" v-if="url">
                        <source :src="url">
                        Sorry, your browser doesn't support embedded videos.
                    </video>

                </div>
            </div>
        </div>
        <div v-if="is_show" class="dialogBox">
            <!--  支付成功 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="PaymentSucceeded">
                    <p class="fs26">无权观看</p>
                    <p class="mt20 color-2 fs20">{{ message }}</p>
                    <p class="color-2 fs20">暂时无权观看日常普及课课程</p>
                </div>
                <div class="btnBox mt20">
                    <div class="btnBox">
                        <div class="cancel" @click="is_show = 0">取消</div>
                        <div class="ml15 Submit" @click="$router.push('/memberIndex')" v-if="message == '对不起您还不是会员'">申请会员
                        </div>
                        <div class="ml15 Submit" @click="$router.push('/occupationalRequirements')" v-else>前往报名</div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
import Menu from '@/components/menu.vue'
import { getMajorDetails } from '@/api/VideoCourse'
export default {
    components: { Menu },
    data() {
        return {
            video_id: '',
            title: '',
            url: '',
            is_show: false,
            message: '',
            dialogVisible: true
        }
    },
    mounted() {
        localStorage.setItem('num', 3)
        this.video_id = this.$route.query.id
        getMajorDetails({ video_id: this.video_id }).then(res => {
            if (res.code == 200) {
                this.title = res.data.video_title
                this.url = res.data.video_src
            } else if (res.code ==  403) {
                this.is_show = true
                this.message = res.msg
            }
        })


    },
    methods: {
        Fallback() {
            this.$router.push('/occupationalRequirements/VideoCourse')
            localStorage.setItem('num', 3)
            localStorage.setItem('title', '视频课程')
        }
    }
}
</script>
  
<style  scoped>
.videoBox {
    margin: 20px;
    width: 1200px;
    height: 952px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.video {
    width: 1080px;
    margin: 20px auto;
    background: #D4D4D4;
}

.index {
    font-weight: 700;
    text-decoration: none;
    transition: color .2s cubic-bezier(.645, .045, .355, 1);
    color: #303133;
    cursor: pointer;
}






input:focus {
    outline: none;
}




.dialogBox>>>.el-dialog__title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.dialogBox>>>.el-dialog {
    width: 720px;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}


.dialogBox>>>.el-dialog__header {
    width: 720px;
    box-sizing: border-box;
    height: 66px;
    border-radius: 10px 10px 0 0;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

.btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.cancel {
    width: 200px;
    height: 48px;
    background: #999999;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.Submit {
    width: 200px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}






.PaymentSucceeded {
    text-align: center;
    margin-bottom: 20px;
}
</style>